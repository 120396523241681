<template>
  <div class="mainList">
    <div class="mainList_operation clearfloat">
      <div class="mainList_operation_inline_block">
        <div class="fl colorBlue" v-if="type == 'second'" style="padding-top: 5px">
          {{ data.vatName }}类型明细
        </div>
        <div class="fl colorBlue" v-if="type == 'third'" style="padding-top: 5px">税率</div>
      </div>
      <div class="fr" v-if="data.id">
        <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
      </div>
    </div>
    <div class="mainList_content">
      <div class="mainList_content_sub" v-if="data.id">
        <el-table
          v-if="type == 'second'"
          row-key="id"
          border
          :data="tableData"
          tooltip-effect="dark"
          height="string"
          :highlight-current-row="false"
          :cell-class-name="setCellClass"
          @row-click="rowClick"
          v-loading="loading"
        >
          <el-table-column align="center" prop="vatName" label="明细名称"></el-table-column>
          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                class="text_Edit_Bgc"
                @click.stop="editData(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="text_Remove_Bgc"
                size="mini"
                @click.stop="delData(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-if="type == 'third'"
          row-key="id"
          border
          :data="tableData"
          tooltip-effect="dark"
          height="string"
          v-loading="loading"
        >
          <el-table-column align="center" prop="vatRate" label="税率">
            <template slot-scope="scope"> {{ scope.row.vatRate }}% </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                class="text_Edit_Bgc"
                @click.stop="editRate(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" class="text_Remove_Bgc" size="mini" @click="delRate(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-result
          v-if="type == 'second'"
          icon="info"
          title=""
          subTitle="点击类型名称查看明细"
        ></el-result>
        <el-result
          v-else-if="type == 'third'"
          icon="info"
          title=""
          subTitle="点击明细名称查看税率"
        ></el-result>
      </div>
    </div>

    <Edit
      v-if="showEditDialog"
      :isShow.sync="showEditDialog"
      :options="options"
      :dialogType="type"
    ></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
  },
  props: {
    // 传进来的数据对象
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    // 标识当前类型 second标识二级明细，third标识三级明细
    type: {
      type: String,
      required: true,
    },

    typeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [], //增值税明细数据
      activeDetailData: {}, //当前选中明细
      loading: false,
      showEditDialog: false,
      dialogType: '',
      options: {},
      highlightedCell: {},
    }
  },
  watch: {
    data: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal.id) {
          this.activeDetailData = {}
          this.highlightedCell = {}
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    getData() {
      this.loading = true
      if (this.type == 'second') {
        this.$api.vat
          .getVatDataList({
            vatType: this.data.vatType,
            pageNow: 1,
            pageSize: 999,
          })
          .then(res => {
            this.loading = false
            this.tableData = res.data.records
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else if (this.type == 'third') {
        this.$api.vat
          .getVatRateList({
            vatData: this.data.vatData,
            pageNow: 1,
            pageSize: 999,
          })
          .then(res => {
            this.loading = false
            this.tableData = res.data.records
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },
    rowClick(row) {
      this.highlightedCell = { id: row.id }
      this.activeDetailData = row
      this.$emit('clickDetail', this.activeDetailData)
    },
    setCellClass({ row }) {
      const isHighlighted = this.highlightedCell && this.highlightedCell.id === row.id
      return isHighlighted ? ['highlight'] : []
    },
    refresh() {
      this.showEditDialog = false
      this.getData()
    },
    add() {
      if (this.type == 'second') {
        this.options = {
          parentName: this.data.vatName,
          vatType: this.data.vatType,
          vatData: '',
          vatName: '',
        }
      } else if (this.type == 'third') {
        this.options = {
          parentName: this.typeName,
          vatType: this.data.vatType,
          vatData: this.data.vatData,
          vatName: this.data.vatName,
          vatRate: '',
        }
      }
      this.showEditDialog = true
    },
    editData(row) {
      this.options = {
        parentName: this.data.vatName,
        ...row.deepClone(),
      }
      this.showEditDialog = true
    },

    delData(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const isHighlighted = this.highlightedCell && this.highlightedCell.id === row.id
          if (isHighlighted) {
            this.$emit('clearRate')
          }
          this.$api.vat
            .deleteVatData(row.id)
            .then(res => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },

    editRate(row) {
      this.options = {
        parentName: this.typeName,
        vatName: this.data.vatName,
        ...row.deepClone(),
      }
      this.showEditDialog = true
    },

    delRate(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.vat
            .deleteVatRate(row.id)
            .then(res => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.dictMain {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 45px);
  padding: 20px;
  .mainList {
    background: #fff;
    .mainList_operation {
      padding: 0;
    }
    .mainList_content_sub {
      padding: 0;
    }
    // &:first-child {
    //   width: 40%;
    // }
    &:nth-child(2) {
      width: 40%;
    }
    &:last-child {
      width: 18%;
    }
  }
}

::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}
</style>
